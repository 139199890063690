export const parentMixin = {
    data() {
        return {
            slider: [
                {
                    color: "#fa1515",
                    text: "parentsSay.sandor_reka_text",
                    author: "parentsSay.sandor_reka_name"
                },
                {
                    color: "#facc15",
                    text: "parentsSay.bokor_tunde_text",
                    author: "parentsSay.bokor_tunde_name"
                },
                {
                    color: "#c715fa",
                    text: "parentsSay.dr_somodi_orsolya_text",
                    author: "parentsSay.dr_somodi_orsolya_name"
                },
                {
                    color: "#fa6515",
                    text: "parentsSay.nagyné_seprényi_dóri_text",
                    author: "parentsSay.nagyné_seprényi_dóri_name"
                },
                {
                    color: "#01ffe8",
                    text: "parentsSay.nagyne_danka_krisztina_text",
                    author: "parentsSay.nagyne_danka_krisztina_name"
                },
                {
                    color: "#5750A0",
                    text: "parentsSay.degrell_csilla_text",
                    author: "parentsSay.degrell_csilla_name"
                },
                {
                    color: "#49ff00",
                    text: "parentsSay.varga_zsuzsanna_text",
                    author: "parentsSay.varga_zsuzsanna_name"
                },
                {
                    color: "#fcb54d",
                    text: "parentsSay.balogh_adrienn_text",
                    author: "parentsSay.balogh_adrienn_name"
                },
                {
                    color: "#fc5b4e",
                    text: "parentsSay.szeman_eniko_text",
                    author: "parentsSay.szeman_eniko_name"
                },
                {
                    color: "#adca69",
                    text: "parentsSay.nagy_fruzsina_text",
                    author: "parentsSay.nagy_fruzsina_name"
                },
                {
                    color: "#84bed6",
                    text: "parentsSay.jenei_hajnalka_text",
                    author: "parentsSay.jenei_hajnalka_name"
                },
                {
                    color: "#c389ce",
                    text: "parentsSay.kurucz_rita_text",
                    author: "parentsSay.kurucz_rita_name"
                },
                {
                    color: "#1518d5",
                    text: "parentsSay.gaal_veronika_text",
                    author: "parentsSay.gaal_veronika_name"
                },
            ],
        }
    }
}
