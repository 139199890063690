<template>
  <div>
    <v-carousel hide-delimiters cycle height="600px">
      <template v-for="(item, index) in items">
        <v-carousel-item
            :key="index"
        >
          <v-row class="flex-nowrap" style="height:100%; margin: 0;">
            <template v-for="(n,i) in columns">
              <template v-if="i < item.src.length">
                <v-col :key="i" style="padding: 0;">
                  <v-sheet v-if="i < item.src.length"
                           height="100%"
                  >
                    <v-row class="fill-height d-flex flex-column justify-space-around"
                           align="center"
                           justify="center"
                           style="margin: 0"
                    >
                      <div class="img-wrapper">
                        <img :src="item.src[i]" alt="Slideshow - Simonyi úti óvoda"/>
                      </div>
                    </v-row>
                  </v-sheet>
                </v-col>
              </template>
            </template>
            <div class="carousel-text-box">
              <v-card class="rounded-lg" color="rgb(255, 255, 255, 0.8)">
                <v-card-text class="black--text">{{ $t(item.text) }}</v-card-text>
              </v-card>
            </div>
          </v-row>
        </v-carousel-item>
      </template>
    </v-carousel>

    <other-task></other-task>

    <parent-said></parent-said>

    <div style="position: relative; padding-top: 25px; padding-bottom: 25px">
      <v-container>
        <v-row>
          <v-col xs="12" sm="12" md="6" lg="6">
            <v-card class="d-flex flex-column align-center fill-height" style="background-color: #B0E26A">
              <v-card-title class="pt-8" style="color: #186b0a">{{ $t('home.green_kindergarten') }}</v-card-title>
              <v-card-text class="d-flex justify-space-around align-center fill-height">
                <v-img src="../assets/images/zoldovoda.png" max-height="145" max-width="145" contain></v-img>
                <div class="pl-5 card__text" style="color: #186b0a">{{ $t('home.green_kindergarten_text') }}</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6">
            <v-card class="d-flex flex-column justify-space-around align-center pa-10 fill-height"
                    style="background-color: #03defc">
              <v-card-title style="word-break: break-word;text-align: center">
                {{ $t('home.excellent_place') }}
              </v-card-title>
              <v-card-text class="card__text">
                {{ $t('home.excellent_place_text') }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <!--      <v-container fluid class="cloud cloud&#45;&#45;top">-->
      <!--        <img class="cloud__image" src="../assets/images/cloud-top.png">-->
      <!--      </v-container>-->
    </div>
  </div>
</template>

<script>
import ParentSaid from "@/components/ParentSaid.vue";
import OtherTask from "@/components/OtherTask.vue";

export default {
  title() {
    return `${this.$t('header.home')} - ${this.$t('header.name')}`
  },
  name: "Home",
  data() {
    return {
      langs: ['hu', 'en'],
      items: [
        {
          src: [require('../assets/images/groups/margareta/5.jpg'), require('../assets/images/groups/tulipan/14.jpg'), require('../assets/images/groups/halacska/2.jpg')],
          text: 'home.quotes.01'
        },
        {
          src: [require('../assets/images/groups/halacska/8.jpg'), require('../assets/images/groups/katica/11.jpg'), require('../assets/images/groups/tulipan/19.jpg')],
          text: 'home.quotes.02'
        },
        {
          src: [require('../assets/images/groups/tulipan/2.jpg'), require('../assets/images/groups/tulipan/13.jpg'), require('../assets/images/groups/halacska/7.jpg')],
          text: 'home.quotes.03'
        },
        {
          src: [require('../assets/images/groups/pillango/2.jpg'), require('../assets/images/groups/tulipan/9.jpg'), require('../assets/images/groups/tulipan/11.jpg')],
          text: 'home.quotes.04'
        },
        {
          src: [require('../assets/images/groups/pillango/1.jpg'), require('../assets/images/groups/pillango/3.jpg'), require('../assets/images/groups/halacska/1.jpg')],
          text: 'home.quotes.05'
        },
        {
          src: [require('../assets/images/groups/suni/5.jpg'), require('../assets/images/groups/suni/1.jpg'), require('../assets/images/groups/suni/2.jpg')],
          text: 'home.quotes.06'
        },
        {
          src: [require('../assets/images/groups/katica/3.jpg'), require('../assets/images/groups/napocska/10.jpg'), require('../assets/images/groups/margareta/8.jpg')],
          text: 'home.quotes.07'
        },
      ]
    }
  },
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 3;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      if (this.$vuetify.breakpoint.sm) {
        return 1;
      }

      return 1;
    }
  },
  components: {
    ParentSaid,
    OtherTask
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.carousel-text-box {
  max-width: 400px;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
    max-width: 100%;
    transform: translate(0, -50%);
    left: 0;
  }
}

.img-wrapper {
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.card {
  &__text {
    text-align: justify;
  }
}
</style>
